import { IEmployee } from '@/types';
import { ColumnDef } from '@tanstack/react-table';
import { Link } from 'react-router-dom';

export const columns: ColumnDef<IEmployee>[] = [
  {
    accessorKey: 'first_name',
    header: 'First Name',
  },
  {
    accessorKey: 'last_name',
    header: 'Last Name',
  },
  {
    accessorKey: 'email',
    header: 'Email',
  },
  {
    accessorKey: 'role',
    header: 'Role',
  },
  {
    accessorKey: 'status',
    header: 'Status',
  },
  {
    accessorKey: '',
    header: 'Chat History',
    cell: ({ cell}) => {
      return <Link className="text-[blue] underline" to={`/app/chat-history/${cell.row.original.user_id}`}>View</Link>;
    }
  },
];
